<template>
  <div class="contentwrapper">
      <div class="contentheadersub isFlex">
        <div class="isFlexGrow">Settings &amp; Account</div>
        <div>
          <b-switch v-model="Me.demo">
            <span v-if="Me.demo">Demo content on</span>
            <span v-else>Demo content off</span>
          </b-switch>
        </div>
      </div>
      <div class="isFlexGrow" style="position: relative;">
        <div class="contentsroll">
          <!-- content: start -->
          <div class="tsk bluebg10 white p11 mb11 m11">
            <div class="txtbold">Current subscription</div>
            <div>
              {{ Me.plan }}
            </div>
            <div class="isFlex">
              <div class="isFlexGrow txtbold" v-if="Me.plancost !== 0">{{Me.currency}} {{ (Me.plancost * ex).toFixed(2) }} / month</div>
              <div class="isFlexGrow txtbold" v-else>FREE</div>
              <div>
                <b-button size="is-small" @click="cancelSub()" v-if="Me.plancost !== 0">Cancel Subscription</b-button>
              </div>
            </div>
          </div>

          <div class="tile">
            <div class="tile is-3 p11 is-vertical" v-for="(pr, index) in Products" :key="index">
              <div class="tsk vlgreybg mb11 p0">
                  <div class="txtbold bluebg11 white roundedtop p7">{{pr.name}}</div>
                  <div class="mt5 mb5 p11 bluebg1" style="min-height: 120px;">{{pr.description}}</div>

                  <div class="isFlex isFlexAlign pv5" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;">{{pr.goals}}</div>
                    <div>Active Projects</div>
                  </div>

                  <div class="isFlex isFlexAlign pv5 bluebg1" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;">{{pr.team}}</div>
                    <div>Members per Goal</div>
                  </div>

                  <div class="isFlex isFlexAlign pv5" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;"><span v-if="pr.intros !== 9999">{{pr.intros}}</span></div>
                    <div><span v-if="pr.intros === 9999">Unlimited</span> Introductions</div>
                  </div>

                  <div class="isFlex isFlexAlign pv5" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;"><span v-if="pr.trees !== 9999">{{pr.trees}}</span></div>
                    <div><span v-if="pr.trees === 9999">Unlimited</span> Shares</div>
                  </div>

                  <div class="isFlex isFlexAlign pv5 bluebg1" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;"><span v-if="pr.blueprints !== 9999">{{pr.blueprints}}</span></div>
                    <div><span v-if="pr.blueprints === 9999">Unlimited</span> Blueprints</div>
                  </div>

                  <div class="isFlex isFlexAlign pv5" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;"><span v-if="pr.peed !== 9999">{{pr.peed}}</span></div>
                    <div><span v-if="pr.peed === 9999">Unlimited</span> PEED value propositions / Customer Profiles</div>
                  </div>

                  <div class="isFlex isFlexAlign pv5 bluebg1" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;">
                      <span v-if="pr.storage === 0">---</span>
                      <span v-if="pr.storage !== 0">{{pr.storage}}</span>
                    </div>
                    <div>
                      <span v-if="pr.storage === 0">Not allowed to upload files <br/></span>
                      <span v-else> GB Storage space <br/></span>
                    </div>
                  </div>

                  <div class="isFlex isFlexAlign pv5" style="min-height: 50px;">
                    <div style="width: 50px; text-align: right; font-size: 1.3em; padding-right: 10px;"></div>
                    <div>
                      <span v-if="pr.adverts">Advertising shown</span>
                      <span v-else>No Advertising shown</span>
                    </div>
                  </div>
                  <div class="txtbold p11 txtc txtlg">
                    <span v-if="pr.cost === 0">FREE</span>
                    <span v-else>{{Me.currency}} {{ (pr.cost * ex).toFixed(2) }} / month</span>
                  </div>
                  <div class="p11">
                    <div v-if="pr.cost === 0">
                      <b-button @click="cancelSub()" type="is-info" expanded>Subscribe</b-button>
                    </div>
                    <div v-else>
                      <b-button type="is-info" expanded @click="payNow({ type: 'Subscribe', title: pr.name, currency: Me.currency, price: (pr.cost * ex).toFixed(2), original: pr.cost, item: {plan: pr.stripeid, quantity: 1 } })">Subscribe</b-button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- content: end -->
        </div>
      </div>
      <!-- <div class="contentfooter isFlex vlgreybg p7">
        <div class="ml5 isFlexGrow"></div>
        <div class="ml5">
          <b-button @click="saveSettings()">Save Settings</b-button>
        </div>
      </div> -->
    </div>
</template>

<script>
// @ is an alias to /src
import { Utils } from '../mixins/utils.js';
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
// import ModalPay from "@/modals/ModalPay.vue";
import {
  Blockquote, // quote-right
  CodeBlock, // code
  Heading, // h1, h2, h3, h4
  HorizontalRule, // horizontal-rule
  OrderedList, // list-ol
  BulletList, // list-ul
  ListItem, //
  TodoItem,
  TodoList, // tasks
  Bold, // bold
  Code, // code ?
  Italic, // italic
  Link, // link
  Strike, // strikethrough
  Underline, //underline
  History // undo, redo
} from "tiptap-extensions";

export default {
  mixins: [Utils],
  name: 'blank',
  components: {
    EditorContent,
    EditorMenuBar
  },
  data() {
    return {
      Me: {},
      editor: null,
      countries: [
        { countryCode: 'GB', value: '44', label: 'UK (+44)' },
        { countryCode: 'US', value: '1', label: 'USA (+1)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: '', value: '', label: '---' },
        { countryCode: 'DZ', value: '213', label: 'Algeria (+213)' },
        { countryCode: 'AD', value: '376', label: 'Andorra (+376)' },
        { countryCode: 'AO', value: '244', label: 'Angola (+244)' },
        { countryCode: 'AI', value: '1264', label: 'Anguilla (+1264)' },
        { countryCode: 'AG', value: '1268', label: 'Antigua & Barbuda (+1268)' },
        { countryCode: 'AR', value: '54', label: 'Argentina (+54)' },
        { countryCode: 'AM', value: '374', label: 'Armenia (+374)' },
        { countryCode: 'AW', value: '297', label: 'Aruba (+297)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'AT', value: '43', label: 'Austria (+43)' },
        { countryCode: 'AZ', value: '994', label: 'Azerbaijan (+994)' },
        { countryCode: 'BS', value: '1242', label: 'Bahamas (+1242)' },
        { countryCode: 'BH', value: '973', label: 'Bahrain (+973)' },
        { countryCode: 'BD', value: '880', label: 'Bangladesh (+880)' },
        { countryCode: 'BB', value: '1246', label: 'Barbados (+1246)' },
        { countryCode: 'BY', value: '375', label: 'Belarus (+375)' },
        { countryCode: 'BE', value: '32', label: 'Belgium (+32)' },
        { countryCode: 'BZ', value: '501', label: 'Belize (+501)' },
        { countryCode: 'BJ', value: '229', label: 'Benin (+229)' },
        { countryCode: 'BM', value: '1441', label: 'Bermuda (+1441)' },
        { countryCode: 'BT', value: '975', label: 'Bhutan (+975)' },
        { countryCode: 'BO', value: '591', label: 'Bolivia (+591)' },
        { countryCode: 'BA', value: '387', label: 'Bosnia Herzegovina (+387)' },
        { countryCode: 'BW', value: '267', label: 'Botswana (+267)' },
        { countryCode: 'BR', value: '55', label: 'Brazil (+55)' },
        { countryCode: 'BN', value: '673', label: 'Brunei (+673)' },
        { countryCode: 'BG', value: '359', label: 'Bulgaria (+359)' },
        { countryCode: 'BF', value: '226', label: 'Burkina Faso (+226)' },
        { countryCode: 'BI', value: '257', label: 'Burundi (+257)' },
        { countryCode: 'KH', value: '855', label: 'Cambodia (+855)' },
        { countryCode: 'CM', value: '237', label: 'Cameroon (+237)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: 'CV', value: '238', label: 'Cape Verde Islands (+238)' },
        { countryCode: 'KY', value: '1345', label: 'Cayman Islands (+1345)' },
        { countryCode: 'CF', value: '236', label: 'Central African Republic (+236)' },
        { countryCode: 'CL', value: '56', label: 'Chile (+56)' },
        { countryCode: 'CN', value: '86', label: 'China (+86)' },
        { countryCode: 'CO', value: '57', label: 'Colombia (+57)' },
        { countryCode: 'KM', value: '269', label: 'Comoros (+269)' },
        { countryCode: 'CG', value: '242', label: 'Congo (+242)' },
        { countryCode: 'CK', value: '682', label: 'Cook Islands (+682)' },
        { countryCode: 'CR', value: '506', label: 'Costa Rica (+506)' },
        { countryCode: 'HR', value: '385', label: 'Croatia (+385)' },
        { countryCode: 'CU', value: '53', label: 'Cuba (+53)' },
        { countryCode: 'CW', value: '599', label: 'Curacao (+599)' },
        { countryCode: 'CY', value: '90392', label: 'Cyprus North (+90392)' },
        { countryCode: 'CY', value: '357', label: 'Cyprus South (+357)' },
        { countryCode: 'CZ', value: '42', label: 'Czech Republic (+42)' },
        { countryCode: 'DK', value: '45', label: 'Denmark (+45)' },
        { countryCode: 'DJ', value: '253', label: 'Djibouti (+253)' },
        { countryCode: 'DM', value: '1809', label: 'Dominica (+1809)' },
        { countryCode: 'DO', value: '1809', label: 'Dominican Republic (+1809)' },
        { countryCode: 'EC', value: '593', label: 'Ecuador (+593)' },
        { countryCode: 'EG', value: '20', label: 'Egypt (+20)' },
        { countryCode: 'SV', value: '503', label: 'El Salvador (+503)' },
        { countryCode: 'GQ', value: '240', label: 'Equatorial Guinea (+240)' },
        { countryCode: 'ER', value: '291', label: 'Eritrea (+291)' },
        { countryCode: 'EE', value: '372', label: 'Estonia (+372)' },
        { countryCode: 'ET', value: '251', label: 'Ethiopia (+251)' },
        { countryCode: 'FK', value: '500', label: 'Falkland Islands (+500)' },
        { countryCode: 'FO', value: '298', label: 'Faroe Islands (+298)' },
        { countryCode: 'FJ', value: '679', label: 'Fiji (+679)' },
        { countryCode: 'FI', value: '358', label: 'Finland (+358)' },
        { countryCode: 'FR', value: '33', label: 'France (+33)' },
        { countryCode: 'GF', value: '594', label: 'French Guiana (+594)' },
        { countryCode: 'PF', value: '689', label: 'French Polynesia (+689)' },
        { countryCode: 'GA', value: '241', label: 'Gabon (+241)' },
        { countryCode: 'GM', value: '220', label: 'Gambia (+220)' },
        { countryCode: 'GE', value: '7880', label: 'Georgia (+7880)' },
        { countryCode: 'DE', value: '49', label: 'Germany (+49)' },
        { countryCode: 'GH', value: '233', label: 'Ghana (+233)' },
        { countryCode: 'GI', value: '350', label: 'Gibraltar (+350)' },
        { countryCode: 'GR', value: '30', label: 'Greece (+30)' },
        { countryCode: 'GL', value: '299', label: 'Greenland (+299)' },
        { countryCode: 'GD', value: '1473', label: 'Grenada (+1473)' },
        { countryCode: 'GP', value: '590', label: 'Guadeloupe (+590)' },
        { countryCode: 'GU', value: '671', label: 'Guam (+671)' },
        { countryCode: 'GT', value: '502', label: 'Guatemala (+502)' },
        { countryCode: 'GN', value: '224', label: 'Guinea (+224)' },
        { countryCode: 'GW', value: '245', label: 'Guinea - Bissau (+245)' },
        { countryCode: 'GY', value: '592', label: 'Guyana (+592)' },
        { countryCode: 'HT', value: '509', label: 'Haiti (+509)' },
        { countryCode: 'HN', value: '504', label: 'Honduras (+504)' },
        { countryCode: 'HK', value: '852', label: 'Hong Kong (+852)' },
        { countryCode: 'HU', value: '36', label: 'Hungary (+36)' },
        { countryCode: 'IS', value: '354', label: 'Iceland (+354)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ID', value: '62', label: 'Indonesia (+62)' },
        { countryCode: 'IR', value: '98', label: 'Iran (+98)' },
        { countryCode: 'IQ', value: '964', label: 'Iraq (+964)' },
        { countryCode: 'IE', value: '353', label: 'Ireland (+353)' },
        { countryCode: 'IL', value: '972', label: 'Israel (+972)' },
        { countryCode: 'IT', value: '39', label: 'Italy (+39)' },
        { countryCode: 'JM', value: '1876', label: 'Jamaica (+1876)' },
        { countryCode: 'JP', value: '81', label: 'Japan (+81)' },
        { countryCode: 'JO', value: '962', label: 'Jordan (+962)' },
        { countryCode: 'KZ', value: '7', label: 'Kazakhstan (+7)' },
        { countryCode: 'KE', value: '254', label: 'Kenya (+254)' },
        { countryCode: 'KI', value: '686', label: 'Kiribati (+686)' },
        { countryCode: 'KP', value: '850', label: 'Korea North (+850)' },
        { countryCode: 'KR', value: '82', label: 'Korea South (+82)' },
        { countryCode: 'KW', value: '965', label: 'Kuwait (+965)' },
        { countryCode: 'KG', value: '996', label: 'Kyrgyzstan (+996)' },
        { countryCode: 'LA', value: '856', label: 'Laos (+856)' },
        { countryCode: 'LV', value: '371', label: 'Latvia (+371)' },
        { countryCode: 'LB', value: '961', label: 'Lebanon (+961)' },
        { countryCode: 'LS', value: '266', label: 'Lesotho (+266)' },
        { countryCode: 'LR', value: '231', label: 'Liberia (+231)' },
        { countryCode: 'LY', value: '218', label: 'Libya (+218)' },
        { countryCode: 'LI', value: '417', label: 'Liechtenstein (+417)' },
        { countryCode: 'LT', value: '370', label: 'Lithuania (+370)' },
        { countryCode: 'LU', value: '352', label: 'Luxembourg (+352)' },
        { countryCode: 'MO', value: '853', label: 'Macao (+853)' },
        { countryCode: 'MK', value: '389', label: 'Macedonia (+389)' },
        { countryCode: 'MG', value: '261', label: 'Madagascar (+261)' },
        { countryCode: 'MW', value: '265', label: 'Malawi (+265)' },
        { countryCode: 'MY', value: '60', label: 'Malaysia (+60)' },
        { countryCode: 'MV', value: '960', label: 'Maldives (+960)' },
        { countryCode: 'ML', value: '223', label: 'Mali (+223)' },
        { countryCode: 'MT', value: '356', label: 'Malta (+356)' },
        { countryCode: 'MH', value: '692', label: 'Marshall Islands (+692)' },
        { countryCode: 'MQ', value: '596', label: 'Martinique (+596)' },
        { countryCode: 'MR', value: '222', label: 'Mauritania (+222)' },
        { countryCode: 'YT', value: '269', label: 'Mayotte (+269)' },
        { countryCode: 'MX', value: '52', label: 'Mexico (+52)' },
        { countryCode: 'FM', value: '691', label: 'Micronesia (+691)' },
        { countryCode: 'MD', value: '373', label: 'Moldova (+373)' },
        { countryCode: 'MC', value: '377', label: 'Monaco (+377)' },
        { countryCode: 'MN', value: '976', label: 'Mongolia (+976)' },
        { countryCode: 'MS', value: '1664', label: 'Montserrat (+1664)' },
        { countryCode: 'MA', value: '212', label: 'Morocco (+212)' },
        { countryCode: 'MZ', value: '258', label: 'Mozambique (+258)' },
        { countryCode: 'MN', value: '95', label: 'Myanmar (+95)' },
        { countryCode: 'NA', value: '264', label: 'Namibia (+264)' },
        { countryCode: 'NR', value: '674', label: 'Nauru (+674)' },
        { countryCode: 'NP', value: '977', label: 'Nepal (+977)' },
        { countryCode: 'NL', value: '31', label: 'Netherlands (+31)' },
        { countryCode: 'NC', value: '687', label: 'New Caledonia (+687)' },
        { countryCode: 'NZ', value: '64', label: 'New Zealand (+64)' },
        { countryCode: 'NI', value: '505', label: 'Nicaragua (+505)' },
        { countryCode: 'NE', value: '227', label: 'Niger (+227)' },
        { countryCode: 'NG', value: '234', label: 'Nigeria (+234)' },
        { countryCode: 'NU', value: '683', label: 'Niue (+683)' },
        { countryCode: 'NF', value: '672', label: 'Norfolk Islands (+672)' },
        { countryCode: 'NP', value: '670', label: 'Northern Marianas (+670)' },
        { countryCode: 'NO', value: '47', label: 'Norway (+47)' },
        { countryCode: 'OM', value: '968', label: 'Oman (+968)' },
        { countryCode: 'PW', value: '680', label: 'Palau (+680)' },
        { countryCode: 'PA', value: '507', label: 'Panama (+507)' },
        { countryCode: 'PG', value: '675', label: 'Papua New Guinea (+675)' },
        { countryCode: 'PY', value: '595', label: 'Paraguay (+595)' },
        { countryCode: 'PE', value: '51', label: 'Peru (+51)' },
        { countryCode: 'PH', value: '63', label: 'Philippines (+63)' },
        { countryCode: 'PL', value: '48', label: 'Poland (+48)' },
        { countryCode: 'PT', value: '351', label: 'Portugal (+351)' },
        { countryCode: 'PR', value: '1787', label: 'Puerto Rico (+1787)' },
        { countryCode: 'QA', value: '974', label: 'Qatar (+974)' },
        { countryCode: 'RE', value: '262', label: 'Reunion (+262)' },
        { countryCode: 'RO', value: '40', label: 'Romania (+40)' },
        { countryCode: 'RU', value: '7', label: 'Russia (+7)' },
        { countryCode: 'RW', value: '250', label: 'Rwanda (+250)' },
        { countryCode: 'SM', value: '378', label: 'San Marino (+378)' },
        { countryCode: 'ST', value: '239', label: 'Sao Tome & Principe (+239)' },
        { countryCode: 'SA', value: '966', label: 'Saudi Arabia (+966)' },
        { countryCode: 'SN', value: '221', label: 'Senegal (+221)' },
        { countryCode: 'CS', value: '381', label: 'Serbia (+381)' },
        { countryCode: 'SC', value: '248', label: 'Seychelles (+248)' },
        { countryCode: 'SL', value: '232', label: 'Sierra Leone (+232)' },
        { countryCode: 'SG', value: '65', label: 'Singapore (+65)' },
        { countryCode: 'SX', value: '1', label: 'Sint Maarten (+1)' },
        { countryCode: 'SK', value: '421', label: 'Slovak Republic (+421)' },
        { countryCode: 'SI', value: '386', label: 'Slovenia (+386)' },
        { countryCode: 'SB', value: '677', label: 'Solomon Islands (+677)' },
        { countryCode: 'SO', value: '252', label: 'Somalia (+252)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'ES', value: '34', label: 'Spain (+34)' },
        { countryCode: 'LK', value: '94', label: 'Sri Lanka (+94)' },
        { countryCode: 'SH', value: '290', label: 'St. Helena (+290)' },
        { countryCode: 'KN', value: '1869', label: 'St. Kitts (+1869)' },
        { countryCode: 'SC', value: '1758', label: 'St. Lucia (+1758)' },
        { countryCode: 'SD', value: '249', label: 'Sudan (+249)' },
        { countryCode: 'SR', value: '597', label: 'Suriname (+597)' },
        { countryCode: 'SZ', value: '268', label: 'Swaziland (+268)' },
        { countryCode: 'SE', value: '46', label: 'Sweden (+46)' },
        { countryCode: 'CH', value: '41', label: 'Switzerland (+41)' },
        { countryCode: 'SI', value: '963', label: 'Syria (+963)' },
        { countryCode: 'TW', value: '886', label: 'Taiwan (+886)' },
        { countryCode: 'TJ', value: '7', label: 'Tajikstan (+7)' },
        { countryCode: 'TH', value: '66', label: 'Thailand (+66)' },
        { countryCode: 'TG', value: '228', label: 'Togo (+228)' },
        { countryCode: 'TO', value: '676', label: 'Tonga (+676)' },
        { countryCode: 'TT', value: '1868', label: 'Trinidad & Tobago (+1868)' },
        { countryCode: 'TN', value: '216', label: 'Tunisia (+216)' },
        { countryCode: 'TR', value: '90', label: 'Turkey (+90)' },
        { countryCode: 'TM', value: '7', label: 'Turkmenistan (+7)' },
        { countryCode: 'TM', value: '993', label: 'Turkmenistan (+993)' },
        { countryCode: 'TC', value: '1649', label: 'Turks & Caicos Islands (+1649)' },
        { countryCode: 'TV', value: '688', label: 'Tuvalu (+688)' },
        { countryCode: 'UG', value: '256', label: 'Uganda (+256)' },
        { countryCode: 'UA', value: '380', label: 'Ukraine (+380)' },
        { countryCode: 'AE', value: '971', label: 'United Arab Emirates (+971)' },
        { countryCode: 'GB', value: '44', label: 'United Kingdom (+44)' },
        { countryCode: 'US', value: '1', label: 'United States of America (+1)' },
        { countryCode: 'UY', value: '598', label: 'Uruguay (+598)' },
        { countryCode: 'UZ', value: '7', label: 'Uzbekistan (+7)' },
        { countryCode: 'VU', value: '678', label: 'Vanuatu (+678)' },
        { countryCode: 'VA', value: '379', label: 'Vatican City (+379)' },
        { countryCode: 'VE', value: '58', label: 'Venezuela (+58)' },
        { countryCode: 'VN', value: '84', label: 'Vietnam (+84)' },
        { countryCode: 'VG', value: '84', label: 'Virgin Islands - British (+1284)' },
        { countryCode: 'VI', value: '84', label: 'Virgin Islands - US (+1340)' },
        { countryCode: 'WF', value: '681', label: 'Wallis and Futuna (+681)' },
        { countryCode: 'YE', value: '969', label: 'Yemen (North) (+969)' },
        { countryCode: 'YE', value: '967', label: 'Yemen (South) (+967)' },
        { countryCode: 'ZM', value: '260', label: 'Zambia (+260)' },
        { countryCode: 'ZW', value: '263', label: 'Zimbabwe (+263)' },
      ],
      Products: [],
      Currency: {},
      Currencies: [
        { code: 'GBP', name: 'British Pound' },
        { code: 'USD', name: 'US Dollar' },
        { code: 'EUR', name: 'Euro' },
        { code: 'ZAR', name: 'South African Rand' },
        { code: 'INR', name: 'Indian rupee' },
      ],
      ex: 1
    }
  },
  created() {
    this.getme();
    this.getProducts();
  },
  methods: {

    async getme() {
      this.Me = await this.DataGet('/me');

      (this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem({ nested: true }),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History()
      ],
      content: this.Me.description,
      onUpdate: ({ getJSON, getHTML }) => {
        this.json = getJSON();
        // this.html = getHTML();
        this.Me.description = getHTML();
      }
      })),
      this.editor.focus();
      this.getCurrency();
    },

    async getProducts() {
      this.Products = await this.DataGet('/products');
    },

    async getCurrency() {
      this.Currency = await this.DataGetRaw('https://api.exchangeratesapi.io/latest?base=GBP');
      this.setEx();
    },

    setEx() {
      this.ex = this.Currency.rates[this.Me.currency];
    },

    async refreshMe() {
      this.Me = await this.DataGet('/me');
    },

    async saveSettings() {
      localStorage.setItem("demo", this.Me.demo);
      const upd = await this.DataSend('put', '/me', this.Me, '', true);
    },

    async cancelSub() {
      // cancelsubscription
      	this.$buefy.dialog.confirm({
          title: "",
          message:
          "Are you sure you want to <b>cancel</b> this subscription? All your data will be available under the Personal Package you just will not be able to go over the package's limitations",
          confirmText: "Cancel Subscription",
          type: "is-danger",
          hasIcon: true,
          icon: 'exclamation',
          onConfirm: async () => {
            const cansub = await this.DataDelete('/cancelsubscription');
            this.refreshMe();
          }
        });

    }
    
  },
  mounted() {
    if (this.$route.params.page === 'success') {
      	this.$buefy.toast.open({ message: "Refresing your subscription information", position: "is-bottom", type: "is-info" });
    }
    if (this.$route.params.page === 'cancel') {
      	this.$buefy.toast.open({ message: "Your purchase has been cancelled", position: "is-bottom", type: "is-danger" });
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
}
</script>

<style>
.ProseMirror {
  border: 1px solid #CCC;
  min-height: 100px;
  padding: 5px;
}
.ProfileDesc > p{
  margin-bottom: 10px;
}
</style>